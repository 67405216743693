import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { space } from "styled-system"
import css from "../../lib/styled-system/css"

import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Grow from "@material-ui/core/Grow"
import Popper from "@material-ui/core/Popper"
import MenuList from "@material-ui/core/MenuList"

import Link from "../utils/Link"
import { Text } from "../atoms/Typography"
import Box from "../atoms/Box"
import Card from "../atoms/Card"

const DropdownButton = styled.button`
  transition-property: opacity;
  ${css({
    color: "text",
    transitionDuration: "fast",
    transitionTimingFunction: "ease",
  })}
  &.active {
    ${css({ color: "primary" })}
  }
  &:hover {
    opacity: 0.7;
  }
  ${space}
`

const MenuItem = styled(Box)`
  &:hover {
    ${css({ backgroundColor: "background.1" })}
  }
`

const NavDropdown = ({
  name,
  links,
  card,
  dropDownOpenOn = "click",
  ...rest
}) => {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault()
      setOpen(false)
    }
  }

  const dropdownOpenHandler = {
    onMouseEnter: dropDownOpenOn === "hover" ? () => setOpen(true) : null,
    onClick: dropDownOpenOn === "click" ? handleToggle : null,
  }
  const clickAwayListenerHandler = {
    onClickAway: handleClose,
    mouseEvent: dropDownOpenOn === "hover" ? "onMouseOut" : "onClick",
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <Box {...rest}>
      <div className={"root"}>
        <div>
          <DropdownButton
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            {...dropdownOpenHandler}
          >
            <Text color="inherit">{name}</Text>
          </DropdownButton>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Card {...card}>
                  <ClickAwayListener {...clickAwayListenerHandler}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      {links.map((link, idx) => (
                        <Link
                          onClick={() => setOpen(false)}
                          key={idx}
                          {...link}
                        >
                          <MenuItem py={3} px={4}>
                            <Text color="text">{link.linkText}</Text>
                          </MenuItem>
                        </Link>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Card>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    </Box>
  )
}

NavDropdown.strapiProps = {
  name: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape(Link.strapiProps)),
  borderRadius: PropTypes.oneOf(["none", "sm", "md", "lg"]),
}

NavDropdown.propTypes = {
  ...NavDropdown.strapiProps,
  ...Box.propTypes,
}

export default NavDropdown
