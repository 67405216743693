import React from "react"
import propTypes from "@styled-system/prop-types"
import styled from "styled-components"
import Link from "../utils/Link"
import Box from "../atoms/Box"

const Img = styled("img")`
  display: block;
  width: 157px;
  height: 19px;
  margin: 0 auto;
`

const MorphicBadge = (props) => {
  return (
    <Link href="https://getmorphic.com" {...props}>
      <Box
        bg="white"
        width="176px"
        height="32px"
        py="7px"
        borderRadius="8px"
        boxShadow="-2px 4px 10px rgba(0, 0, 0, 0.1)"
      >
        <Img src="/morphic-badge.png" alt="Created with Morphic" />
      </Box>
    </Link>
  )
}

MorphicBadge.propTypes = {
  ...propTypes.space,
  ...propTypes.layout,
}

export default MorphicBadge
