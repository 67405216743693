import React from "react"
import { graphql, notGatsby } from "gatsby"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import Box from "../atoms/Box"
import styled from "styled-components"

const GatsbyImageWrapper = styled.div`
  position: relative;
  overflow: hidden;

  img {
    bottom: 0;
    height: 100%;
    left: 0;
    margin: 0;
    max-width: none;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    object-fit: cover;
  }
`

const getOptimizedUrl = (url, formats) => {
  let optimizedUrl = url
  if (formats) {
    if (formats.large) optimizedUrl = formats.large.url
    else if (formats.medium) optimizedUrl = formats.medium.url
    else if (formats.small) optimizedUrl = formats.small.url
    else if (formats.thumbnail) optimizedUrl = formats.thumbnail.url
  }
  return optimizedUrl
}

const Img = ({ className, url, formats, alternativeText, imgStyle }) => {
  return (
    <img
      className={className}
      src={getOptimizedUrl(url, formats)}
      alt={alternativeText}
      style={imgStyle}
    />
  )
}

const Image = (props) => {
  const {
    height,
    width,
    images, // should be in format [{image: imageFile}, {image: imageFile, media: String}, ...]
    style,
    as,
    className,
    imgClassName,
    imgStyle,
  } = props
  let aspectRatio = null
  if (height && width) aspectRatio = ((100 * height) / width).toFixed(1)
  if (images)
    aspectRatio = (
      (100 * images[0].image.height) /
      images[0].image.width
    ).toFixed(1)

  // Automatically include 100% width so constrained images fill container
  const wrapperStyle = { width: "100%", ...style }

  if (
    notGatsby ||
    (!props.image && !props.imageFile?.childImageSharp && !images)
  ) {
    // gifs don't have childImageSharp
    // Mimic what Gatsby Image looks like
    return (
      <GatsbyImageWrapper style={wrapperStyle} as={as} className={className}>
        <Box aria-hidden="true" width="100%" pb={`${aspectRatio}%`} />
        <picture>
          {images &&
            images
              .slice(1)
              .map(({ image, media }, i) => (
                <source
                  srcSet={getOptimizedUrl(image.url, image.format)}
                  media={media}
                  key={i}
                />
              ))}
          {images ? (
            <Img
              {...images[0].image}
              className={imgClassName}
              style={imgStyle}
            />
          ) : (
            <Img {...props} className={imgClassName} style={imgStyle} />
          )}
        </picture>
      </GatsbyImageWrapper>
    )
  }

  // Normal Gatsby Image
  // Props: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image/#shared-props
  const {
    alternativeText,
    imageFile,
    image,
    alt,
    loading,
    backgroundColor,
    objectFit,
    objectPosition,
    draggable,
  } = props

  let artDirectedImages
  if (images) {
    // https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image/#withartdirection
    artDirectedImages = withArtDirection(
      getImage(images[0].image.imageFile), // first image is default
      images.slice(1).map(({ media, image }) => ({
        media,
        image: getImage(image.imageFile),
      }))
    )
  }

  return (
    <GatsbyImage
      image={image || artDirectedImages || getImage(imageFile)}
      alt={alt || alternativeText}
      as={as}
      loading={loading}
      className={className}
      style={wrapperStyle}
      imgClassName={imgClassName}
      imgStyle={imgStyle}
      backgroundColor={backgroundColor}
      objectFit={objectFit}
      objectPosition={objectPosition}
      draggable={draggable}
    />
  )
}

export default Image

export const query = graphql`
  fragment Image on Strapi_UploadFile {
    id
    url
    alternativeText
    height
    width
  }
`
