import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import ThemeSwitcher from "../utils/ThemeSwitcher"
import Reveal from "../utils/Reveal"

import Box from "../atoms/Box"
import Flex from "../atoms/Flex"
import Footer from "../atoms/Footer"
import FlexGrid from "../atoms/FlexGrid"
import StandardPadding from "../atoms/StandardPadding"

import FooterText from "../molecules/FooterText"
import LinkList from "../molecules/LinkList"

const FooterDefault = ({
  theme,
  defaultTheme,
  bg,
  logo,
  text,
  textPosition,
  linkLists,
  lastSectionStyling,
  hideMorphicBadget,
  mt,
  ...rest
}) => {
  const isLinkLists = linkLists?.length > 0
  const textRight = textPosition === "right"
  const marginTop =
    lastSectionStyling?.sectionBreak === "slope" ? "-160px" : null
  const pt = lastSectionStyling?.sectionBreak === "slope" ? "157px" : 6
  const pb = lastSectionStyling?.sectionBreak === "slope" ? 9 : 6
  return (
    <ThemeSwitcher theme={theme || defaultTheme}>
      <Footer bg={`background.${bg}`} mt={mt ? mt : marginTop}>
        <StandardPadding py={null} pt={pt} pb={pb} {...rest}>
          <Flex flexDirection={textRight ? "row-reverse" : ""}>
            <Box
              display={{
                _: "none",
                md: linkLists?.length <= 2 ? "block" : "none",
                lg: linkLists?.length <= 3 ? "block" : "none",
                xl: linkLists?.length <= 4 ? "block" : "none",
              }}
              width={{ md: "50%", lg: linkLists?.length > 2 ? "40%" : "50%" }}
            >
              <Reveal>
                <FooterText
                  logo={logo}
                  text={text}
                  hideMorphicBadget={hideMorphicBadget}
                  align={textRight ? "right" : "left"}
                  pr={textRight ? 0 : 5}
                  pl={textRight ? 5 : 0}
                />
              </Reveal>
            </Box>
            {isLinkLists && (
              <Box
                width={{
                  _: "100%",
                  md: "50%",
                  lg: linkLists?.length > 2 ? "60%" : "50%",
                }}
              >
                <FlexGrid
                  columns={{
                    _: Math.min(2, linkLists.length),
                    sm: Math.min(
                      linkLists.length % 2 === 0 ? 2 : 3, // if even, stay at 2. Otherwise 3
                      linkLists.length
                    ),
                    md: Math.min(4, linkLists.length),
                  }}
                  gutter={5}
                >
                  {linkLists.map((linkList, i) => (
                    <LinkList {...linkList} key={i} />
                  ))}
                </FlexGrid>
              </Box>
            )}
          </Flex>
          <Box
            display={{
              _: "block",
              md: linkLists?.length <= 2 ? "none" : "block",
              lg: linkLists?.length <= 3 ? "none" : "block",
              xl: linkLists?.length <= 4 ? "none" : "block",
            }}
          >
            <Reveal>
              <FooterText
                logo={logo}
                text={text}
                align={textRight ? "right" : "left"}
                mt={isLinkLists ? 6 : 0}
                hideMorphicBadget={hideMorphicBadget}
              />
            </Reveal>
          </Box>
        </StandardPadding>
      </Footer>
    </ThemeSwitcher>
  )
}

export default FooterDefault

FooterDefault.strapiProps = {
  theme: PropTypes.oneOf(["default", "light", "dark", "highlight"]),
  defaultTheme: PropTypes.oneOf(["default", "light", "dark", "highlight"])
    .isRequired,
  bg: PropTypes.number.isRequired,
  logo: PropTypes.object,
  text: PropTypes.string,
  textPosition: PropTypes.oneOf(["left", "right"]).isRequired,
  linkLists: PropTypes.arrayOf(PropTypes.shape(LinkList.strapiProps)),
}

FooterDefault.propTypes = {
  ...FooterDefault.strapiProps,
}

export const query = graphql`
  fragment FooterDefault on Strapi_ComponentFootersDefault {
    defaultTheme: theme
    bg
    logo {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            width: 176
            placeholder: NONE
            layout: CONSTRAINED
          )
        }
      }
    }
    text
    textPosition
    linkLists {
      ...LinkList
    }
    hideMorphicBadget
  }
`
