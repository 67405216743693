import React from "react"
import propTypes from "@styled-system/prop-types"
import styled from "styled-components"
import { space, layout, compose } from "styled-system"
import css from "../../lib/styled-system/css"
import { splitProps } from "../../lib/styled-system/helpers"

import Link from "../utils/Link"
import { Text } from "../atoms/Typography"

const StyledLink = styled(Link)`
  transition-property: opacity;
  ${css({
    color: "text",
    transitionDuration: "fast",
    transitionTimingFunction: "ease",
  })}
  &.active {
    ${css({ color: "primary" })}
  }
  &:hover {
    opacity: 0.7;
  }
  ${compose(space, layout)}
`

/**
 * NavLink - Used mainly for headers and footers
 */
const NavLink = ({ linkText, ...rest }) => {
  const { typography, rest: linkProps } = splitProps(rest, "typography")
  return (
    <StyledLink {...linkProps}>
      <Text color="inherit" {...typography}>
        {linkText}
      </Text>
    </StyledLink>
  )
}

NavLink.strapiProps = {
  ...Link.strapiProps,
}

NavLink.propTypes = {
  ...NavLink.strapiProps,
  ...propTypes.space,
}

export default NavLink
