import React from "react"
import PropTypes from "prop-types"

import Link from "../utils/Link"
import Image from "../utils/Image"

import Box from "../atoms/Box"
import Flex from "../atoms/Flex"
import { Paragraph } from "../atoms/Typography"
import MorphicBadge from "../atoms/MorphicBadge"

const FooterText = ({ logo, text, align, hideMorphicBadget, ...rest }) => {
  return (
    <Flex
      flexDirection="column"
      alignItems={align === "right" ? "flex-end" : ""}
      {...rest}
    >
      {logo && (
        <Box width={{ _: 9, md: 11 }} height={3} mb={3}>
          <Link to="/">
            <Image
              {...logo}
              style={{ height: "100%" }}
              imgStyle={{
                objectFit: "contain",
                objectPosition: "left center",
              }}
            />
          </Link>
        </Box>
      )}
      {text && (
        <Paragraph as="div" mb={3}>
          {text}
        </Paragraph>
      )}
      {!hideMorphicBadget && <MorphicBadge mt={logo || text ? 3 : 0} />}
    </Flex>
  )
}

FooterText.propTypes = {
  logo: PropTypes.object,
  text: PropTypes.string,
  align: PropTypes.oneOf(["left", "right"]).isRequired,
  hideMorphicBadget: PropTypes.bool,
  ...Box.propTypes,
}

export default FooterText
