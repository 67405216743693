import React from "react"
import PropTypes from "prop-types"
import Box from "../atoms/Box"

const StandardPadding = ({ children, verticalPadding, py, ...rest }) => {
  let verticalPaddingPy
  switch (verticalPadding) {
    case "lg":
      verticalPaddingPy = { _: 11, md: 12 }
      break
    case "default":
    case "md":
      verticalPaddingPy = { _: 7, md: 9 }
      break
    case "sm":
      verticalPaddingPy = { _: 5, md: 7 }
      break
    case "custom":
    default:
      verticalPaddingPy = py
  }

  return (
    <Box
      px={{ _: 5, lg: 7, xl: 4 }}
      maxWidth={70}
      mx="auto"
      py={verticalPaddingPy}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default StandardPadding

StandardPadding.defaultProps = {
  py: { _: 7, md: 9 },
}

StandardPadding.propTypes = {
  children: PropTypes.node.isRequired,
  ...Box.propTypes,
}
