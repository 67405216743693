import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery, notGatsby } from "gatsby"
import styled from "styled-components"
import { color, compose } from "styled-system"
import { animation } from "../../lib/styled-system/system"
import css from "../../lib/styled-system/css"

import CSSTransition from "../utils/CSSTransition"
import CloseIcon from "../icons/Close"
import { StyledHeader } from "../headers/HeaderDefault"

import Button from "../atoms/Button"
import ButtonElement from "../atoms/ButtonElement"
import StandardPadding from "../atoms/StandardPadding"
import Flex from "../atoms/Flex"
import NavLink from "../atoms/NavLink"

import NavDropdownMobile from "../molecules/NavDropdownMobile"

// https://stackoverflow.com/questions/42660907/using-reactcsstransitiongroup-with-styled-component
const StyledMenu = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  transform: translateX(100%);
  transition-property: transform;
  ${css({
    bg: "background.0",
    zIndex: "mobileMenu",
    transitionDuration: "md",
    transitionTimingFunction: "ease-in-out",
  })}
  ${compose(color, animation)}
  &.menu-enter {
    transform: translateX(100%);
  }
  &.menu-enter-active {
    transform: translateX(0%);
  }
  &.menu-enter-done {
    transform: translateX(0%);
  }
  &.menu-exit {
    transform: translateX(0%);
  }
  &.menu-exit-active {
    transform: translateX(100%);
  }
  &.menu-exit-done {
    transform: translateX(100%);
  }
`

const MobileMenu = ({
  isOpen,
  onDismiss,
  links,
  logo,
  bg,
  button,
  button2,
}) => {
  const data = useStaticQuery(graphql`
    query {
      allSitePage {
        nodes {
          context {
            pageId
          }
          path
        }
      }
    }
  `)

  let showHome = false
  if (!notGatsby) {
    const pagesDict = {}
    data.allSitePage.nodes.forEach(page => {
      const pageId = page.context?.pageId
      if (pageId) pagesDict[pageId] = page.path
    })

    showHome = !links.some(link => {
      if (link.__typename === "Strapi_ComponentUtilsLink" && link.to) {
        return pagesDict[link.to] === "/"
      } else if (
        link.__typename === "Strapi_ComponentUtilsDropdown" &&
        link.links.length > 0
      ) {
        return link.links.some(link => {
          if (link.to) {
            return pagesDict[link.to] === "/"
          } else return false
        })
      } else return false
    })
  }

  return (
    <CSSTransition
      in={isOpen}
      timeout="md"
      classNames="menu"
      mountOnEnter
      unmountOnExit
    >
      <StyledMenu bg={bg}>
        <StyledHeader as="div" bg={bg} boxShadow="none">
          <StandardPadding py={0} height="100%">
            <Flex
              alignItems="center"
              justifyContent="space-between"
              height="100%"
            >
              {logo}
              <ButtonElement onClick={onDismiss} width={2} height={2}>
                <CloseIcon color="text" />
              </ButtonElement>
            </Flex>
          </StandardPadding>
        </StyledHeader>
        <StandardPadding py={0}>
          <Flex flexDirection="column" alignItems="center" mt={6}>
            {showHome && (
              <NavLink
                fontSize={3}
                partiallyActive={true}
                to="/"
                linkText="Home"
                onClick={onDismiss}
              />
            )}
            {links.map((item, i) => {
              switch (item.__typename || item.__component) {
                case "Strapi_ComponentUtilsLink":
                case "utils.link":
                  return (
                    <NavLink
                      mt={5}
                      fontSize={3}
                      partiallyActive={true}
                      onClick={onDismiss}
                      key={i}
                      {...item}
                    />
                  )
                case "Strapi_ComponentUtilsDropdown":
                case "utils.dropdown":
                  return (
                    <NavDropdownMobile
                      fontSize={3}
                      mt={5}
                      onDismiss={onDismiss}
                      key={i}
                      {...item}
                    />
                  )
                default:
                  return null
              }
            })}
            {button && <Button className="nav-button" {...button} mt={6} />}
            {button2 && <Button className="nav-button" {...button2} />}
          </Flex>
        </StandardPadding>
      </StyledMenu>
    </CSSTransition>
  )
}

MobileMenu.propTypes = {
  isOpen: PropTypes.bool,
  onDismiss: PropTypes.func,
  links: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(NavLink.strapiProps),
      PropTypes.shape(NavDropdownMobile.strapiProps),
    ])
  ),
  logo: PropTypes.element,
  bg: PropTypes.string,
}

export default MobileMenu
