import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import HeaderDefault from "../headers/HeaderDefault"
import HeaderRadian from "../headers/HeaderRadian"

const Headers = ({ site, isHomepage, pageId }) => {
  const header = site.header[0]
  const props = { siteTitle: site.title }

  switch (header.__typename || header.__component) {
    case "Strapi_ComponentHeadersDefault":
    case "headers.default":
      return (
        <HeaderDefault
          {...header}
          {...props}
          site={site}
          isHomepage={isHomepage}
        />
      )
    case "Strapi_ComponentHeadersRadian":
    case "headers.radian":
      return <HeaderRadian {...header} {...props} />
    default:
      return null
  }
}

export default Headers

Headers.propTypes = {
  site: PropTypes.object.isRequired,
}

export var query = graphql`
  fragment Headers on Strapi_SiteHeaderDynamicZone {
    __typename
    ... on Strapi_ComponentHeadersDefault {
      ...HeaderDefault
    }
    ... on Strapi_ComponentHeadersRadian {
      ...HeaderRadian
    }
  }
`
