import React, { useContext } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import propTypes from "@styled-system/prop-types"
import styled, { ThemeContext } from "styled-components"

import ThemeSwitcher from "../utils/ThemeSwitcher"
import Image from "../utils/Image"

import Box from "../atoms/Box"
import Flex from "../atoms/Flex"
import Button from "../atoms/Button"
import StandardPadding from "../atoms/StandardPadding"

const DividingLine = ({ height }) => {
  return (
    <Box
      position="relative"
      width="1px"
      height={height}
      bg="text"
      style={{ opacity: 0.3 }}
    />
  )
}

const StyledBox = styled(Box)`
  ${props =>
    props.sectionBreak === "slope"
      ? `clip-path: polygon(100% 100%, 100% 0%, 0% 0%, 0% calc(100% - 80px))` //64px
      : null};
  @media only screen and (max-width: ${props =>
      props.sectionBreak === "slope" && props.theme.breakpoints.md}) {
    clip-path: polygon(100% 100%, 100% 0%, 0% 0%, 0% calc(100% - 48px)); //32px
  }
`

const Section = ({
  id,
  sectionId,
  children,
  theme,
  buttons,
  backgroundImageMobile,
  backgroundImageDesktop,
  overlayOpacity,
  bg,
  sectionDivider,
  sectionBreak,
  prevSection,
  zIndex,
  verticalPadding,
  customVerticalPadding,
  noStandardPadding,
  ...rest
}) => {
  noStandardPadding = noStandardPadding && verticalPadding !== "custom"
  verticalPadding = !verticalPadding ? "md" : verticalPadding
  customVerticalPadding = !customVerticalPadding ? 0 : customVerticalPadding

  const background = `background.${bg}`
  const { breakpoints } = useContext(ThemeContext)

  let images
  if (backgroundImageMobile && backgroundImageDesktop) {
    const breakpointTablet = parseInt(breakpoints.md)
    images = [
      { image: backgroundImageDesktop },
      {
        image: backgroundImageMobile,
        media: `(max-width: ${breakpointTablet}px)`,
      },
    ]
  }

  let image
  if (images) {
    image = (
      <Image
        alternativeText={backgroundImageDesktop.alternativeText}
        images={images}
        style={{ position: "static" }}
      />
    )
  } else if (backgroundImageDesktop) {
    image = <Image {...backgroundImageDesktop} style={{ position: "static" }} />
  } else if (backgroundImageMobile) {
    image = <Image {...backgroundImageMobile} style={{ position: "static" }} />
  }

  let dividerHeight
  switch (verticalPadding) {
    case "lg":
      dividerHeight = { _: 4, md: 5 }
      break
    case "default":
    case "md":
      dividerHeight = { _: 2, md: 3 }
      break
    case "sm":
      dividerHeight = { _: 1, md: 2 }
      break
    case "custom":
      const defaultPadding =
        Math.floor(customVerticalPadding / 32) >= 2
          ? customVerticalPadding - 16
          : 32
      dividerHeight = {
        _: `${defaultPadding}px`,
        md: `${customVerticalPadding}px`,
      }
      //overwritting sectionBreak default padding
      rest.pt = "0px"
      rest.pb = "0px"
      break
    default:
      dividerHeight = { _: 2, md: 3 }
  }

  let ctaButtons =
    buttons && buttons.length
      ? buttons.map(button => <Button {...button} mx={4} my={3} />)
      : null

  const innerContent = (
    <>
      {children}
      {ctaButtons && (
        <Flex width="100%" justifyContent="center" flexWrap="wrap" mt={6}>
          {ctaButtons}
        </Flex>
      )}
    </>
  )

  return (
    <ThemeSwitcher theme={theme}>
      <StyledBox
        as="section"
        id={sectionId || id}
        position="relative"
        bg={image ? null : background}
        zIndex={zIndex}
        sectionBreak={sectionBreak}
        // pb={sectionBreak === "slope" ? { _: 7, md: 10, lg: 10 } : null}
        {...rest}
      >
        {image && (
          <>
            <Box
              position="absolute"
              zIndex={-1}
              top="0"
              right="0"
              bottom="0"
              left="0"
              bg={background}
              overflow="hidden"
            >
              {image}
            </Box>
            <Box
              position="absolute"
              zIndex={1}
              top="0"
              right="0"
              bottom="0"
              left="0"
              bg={background}
              opacity={overlayOpacity || 0}
            />
          </>
        )}
        {!noStandardPadding && (
          <StandardPadding
            verticalPadding={verticalPadding}
            py={dividerHeight}
            position="relative"
            zIndex={10}
          >
            {innerContent}
          </StandardPadding>
        )}
        {noStandardPadding && innerContent}
        {sectionDivider === "line" && (
          <Flex
            bottom={0}
            position="absolute"
            width="100%"
            justifyContent="center"
          >
            <DividingLine height={dividerHeight} />
          </Flex>
        )}
      </StyledBox>
    </ThemeSwitcher>
  )
}

Section.defaultProps = {
  theme: "default",
}

Section.strapiProps = {
  theme: PropTypes.oneOf(["default", "light", "dark", "highlight"]).isRequired,
  bg: PropTypes.number.isRequired,
  backgroundImageMobile: PropTypes.object,
  backgroundImageDesktop: PropTypes.object,
  overlayOpacity: PropTypes.number,
  sectionDivider: PropTypes.oneOf(["default", "line"]),
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  ...propTypes.color,
  ...Section.strapiProps,
}

export default Section

export const query = graphql`
  fragment Section on Strapi_ComponentAtomsSection {
    id
    bg
    theme
    buttons {
      ...Button
    }
    backgroundImageMobile {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
    }
    backgroundImageDesktop {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
    }
    overlayOpacity
    hidden
    sectionDivider
    verticalPadding
    sectionBreak
    customVerticalPadding
  }
`
